<template></template>
<script>
import { encode } from "@/services/encode";
export default {
  data() {
    return {
      linecode: "",
      access_token: "",
      id_token: "",
      tokenData: [],
    };
  },
  async created() {
    await this.getParam();
    await this.getToken();
  },
  methods: {
    getParam() {
      const urlParams = this.$route.query;
      console.log("param", urlParams.code);
      this.linecode = urlParams.code;
    },
    async getToken() {
      var axios = require("axios");
      var qs = require("qs");
      var data = qs.stringify({
        grant_type: "authorization_code",
        code: this.linecode,
        redirect_uri: "https://readylottery.com/redirect",
        client_id: "1655949122",
        client_secret: "e443290e4b676f922c8d95d64c646712",
      });
      var config = {
        method: "post",
        url: "https://api.line.me/oauth2/v2.1/token",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };

      var that = this;
      await axios(config)
        .then(function (response) {
          //   console.log(JSON.stringify(response.data));
          console.log("donut", response.data);
          that.verify(response.data.access_token, response.data.id_token);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async verify(access_token, id_token) {
      var axios = require("axios");
      var qs = require("qs");
      var data = qs.stringify({
        id_token: id_token,
        client_id: "1655949122",
      });
      var config = {
        method: "post",
        url: "https://api.line.me/oauth2/v2.1/verify",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };
      var that = this;
      await axios(config)
        .then(function (response) {
          console.log("verifyแล้ว", response.data);
          that.login(response.data.sub);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async login(sub) {
      const data = {
        socialId: sub,
        telNumber: "",
      };
      console.log("dataloginordinary", data);
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/users/login`,
        data
      );
      console.log("response", response);
      if (response.data.response_status === "SUCCESS") {
        localStorage.setItem("DDLotUser", encode(response.data.data));
        console.log("ล้อคอินไลน์ผ่าน");
        this.$router.push({ path: "member" });
      } else {
        this.$swal.fire({
          icon: "warning",
          title: "ไม่มีข้อมูลผู้ใช้งาน โปรดติดต่อเจ้าหน้าที่",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
};
</script>